import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { compareDesc, parseISO } from 'date-fns';
import { Grid, Type, Color, Breakpoints } from '../StyleGuide';
import curriculumService from '../services/curriculumService';
import cacheService from '../services/cacheService';
import LoadingState from '../components/LoadingState';
import { ErrorMessage } from '../components/AlertMessages';
import { FullWidthCard, Container, Image, SubHeaderWithButton } from '../components/Layout';
import { OutlineButton, SmallRoundedIconButton } from '../components/Buttons';
import BibleStudyNavigation from './components/BibleStudyNavigation';
import { MaterialList, MaterialListItem } from './MaterialList';
import { InlineList } from '../components/Layout';
import { DeemphasizedBadge } from '../components/Badge';
import useCurriculum from '../hooks/useCurriculum';

const VolumeName = styled.h3`
  margin: 0 0 ${Grid._3};
  font-weight: ${Type.Weight.semibold};
  color: ${Type.Color.dark};
  font-size: ${Type.Scale._5};
`;

const FullWidthCardContainer = styled(FullWidthCard)`
  padding-top: ${Grid._5};
`;

const VolumeSectionHeaderContainer = styled(SubHeaderWithButton)`
  padding: ${Grid._5} 0;

  &:not(:last-child) {
    border-bottom: ${props => (props.showContents ? 'none' : `1px solid ${Color.Gray._10}`)};
  }

  h4 {
    margin: 0;
  }
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${Grid._4};
  margin-bottom: ${Grid._5};

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    margin-bottom: 0;
  }
`;

const UpDownIcon = styled.i.attrs({ className: 'fas fa-chevron-down' })`
  transform: rotate(${props => (props.isUp ? '180' : '0')}deg);
`;

const getSectionToggleState = sectionId => !!cacheService.get(`volume-section-toggle--${sectionId}`);
const setSectionToggleState = (sectionId, state) => cacheService.set(`volume-section-toggle--${sectionId}`, state, 60);

const VolumeSection = ({ section }) => {
  const [showContents, setShowContents] = useState(getSectionToggleState(section.id));

  const download = (path, name) => curriculumService.downloadVolumeMaterial(path, name);

  useEffect(() => {
    setSectionToggleState(section.id, showContents);
  }, [section.id, showContents]);

  return (
    <div data-tracking-component={'volume-header'} data-tracking-id={section.header}>
      <VolumeSectionHeaderContainer showContents={showContents}>
        <HeaderTitleContainer>
          <SmallRoundedIconButton onClick={() => setShowContents(prev => !prev)} aria-label="toggle contents">
            <UpDownIcon isUp={showContents} />
          </SmallRoundedIconButton>
          <h4>{section.header}</h4>
        </HeaderTitleContainer>
        {section.archivePath && (
          <OutlineButton onClick={() => download(section.archivePath, section.archiveName)}>Download All</OutlineButton>
        )}
      </VolumeSectionHeaderContainer>
      {showContents && (
        <MaterialList>
          {section.materials
            .sort((a, b) => !!b.fileName - !!a.fileName)
            .map(material => {
              const isPlus = material.package === 'plus';
              return (
                <MaterialListItem
                  key={material.materialId}
                  disabled={!material.fileName}
                  onClick={() => download(material.filePath, material.fileName)}
                  data-tracking-component="volume-material"
                  data-tracking-id={material.name}
                >
                  <div className="material-text">
                    <p className="material-name">
                      {material.name}
                      {isPlus && <DeemphasizedBadge style={{ marginLeft: '8px' }}>Plus</DeemphasizedBadge>}
                    </p>
                  </div>
                  <SmallRoundedIconButton>
                    <i className="fas fa-download fa-fw"></i>
                  </SmallRoundedIconButton>
                </MaterialListItem>
              );
            })}
        </MaterialList>
      )}
    </div>
  );
};

VolumeSection.propTypes = {
  section: PropTypes.object.isRequired,
};

export default function ManageBibleStudyAdvancePlanning() {
  const [data, setData] = useState({ isLoading: true });
  const { brand, ageCategory } = useCurriculum();
  const { curriculumId } = useParams();

  const [crumbs] = useState([
    { name: 'Curriculum', route: '#/bible-studies' },
    { name: `${brand.name}: ${ageCategory}` },
  ]);

  useEffect(() => {
    curriculumService
      .getAdvancePlanningMaterials(curriculumId)
      .then(volumes => {
        setData({
          isLoading: false,
          volumes: volumes.sort((a, b) => compareDesc(parseISO(a.startDate), parseISO(b.startDate))),
        });
      })
      .catch(reason => {
        console.error(reason);
        setData({ isLoading: false, isError: true });
      });
  }, []);

  return (
    <>
      <BibleStudyNavigation
        isLoading={data.isLoading}
        crumbs={crumbs}
        curriculumId={curriculumId}
        activeTab="advance-planning"
      ></BibleStudyNavigation>
      <FullWidthCardContainer>
        <Container>
          {data.isLoading ? (
            <LoadingState />
          ) : data.isError ? (
            <ErrorMessage>
              A problem occurred showing this page. Please refresh the page to try again.{' '}
              <a href="#/help">Contact Us</a>
            </ErrorMessage>
          ) : (
            <>
              {data.volumes.map(volume => (
                <div
                  key={volume._id}
                  className="grid-container grid-col-4 grid-sm-col-8 grid-gap-32"
                  style={{ paddingBottom: Grid._10 }}
                  data-tracking-component={'volume'}
                  data-tracking-id={volume.name}
                >
                  <div className="grid-col-span-4 grid-sm-col-span-2">
                    <Image src={volume.imageUrl} />
                  </div>
                  <div className="grid-col-span-4 grid-sm-col-span-6">
                    <VolumeName>{volume.name}</VolumeName>
                    <InlineList content={'•'} fontSize="16px">
                      {volume.number && <li data-qa-hook="volumeNumber">Volume {volume.number}</li>}
                      {volume.quarter && (
                        <li data-qa-hook="quarterYear">
                          {' '}
                          {volume.quarter.season} {volume.quarter.year}{' '}
                        </li>
                      )}
                    </InlineList>
                    {volume.sections.map(section =>
                      section.materials.length ? <VolumeSection key={section.id} section={section} /> : null
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
        </Container>
      </FullWidthCardContainer>
    </>
  );
}
