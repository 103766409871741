// eslint-disable-next-line no-undef
angular.module('lwNamb').config([
  '$routeProvider',
  'blockUIConfigProvider',
  'properties',
  'config',
  function ($routeProvider, blockUIConfigProvider, props, config) {
    // Angular component pages used in React flows

    $routeProvider.when('/invite/:orgId/preview-and-send/:inviteId?', {
      templateUrl: 'partials/views/invite-preview-and-send.html',
      controller: 'InviteSendEmailsCtrl',
      section: props.sections.invite,
    });

    $routeProvider.when('/invite/:orgId/send-emails/:inviteId?/:settingsId?', {
      templateUrl: 'partials/views/invite-add-emails.html',
      controller: 'InviteAddEmailsCtrl',
      section: props.sections.invite,
    });
    // react-router routes
    $routeProvider.when('/people', {
      template: '<react-app user="user" org="org" />',
      tab: 'People',
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    $routeProvider.when('/people/:postfix*', {
      template: '<react-app user="user" org="org" />',
      tab: 'People',
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    $routeProvider.when('/groups', {
      template: '<react-app user="user" org="org" />',
      tab: 'People',
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    $routeProvider.when('/groups/:postfix*', {
      template: '<react-app user="user" org="org" />',
      tab: 'People',
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    $routeProvider.when('/search', {
      template: '<react-app user="user" org="org" />',
      reloadOnUrl: false,
      reloadOnSearch: false,
      section: props.sections.catalog,
    });

    $routeProvider.when('/roles', {
      template: '<react-app user="user" org="org" />',
      tab: 'Organization',
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    $routeProvider.when('/roles/:postfix*', {
      template: '<react-app user="user" org="org" />',
      tab: 'Organization',
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    $routeProvider.when('/account-profile', {
      template: '<react-app user="user" org="org" />',
      tab: 'Profile',
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    $routeProvider.when('/bible-studies', {
      template: '<react-app user="user" org="org" />',
      tab: 'Curriculum',
      reloadOnUrl: false,
      reloadOnSearch: false,
      publiclyAccessible: true,
    });

    $routeProvider.when('/manage-bible-study/:postfix*', {
      template: '<react-app user="user" org="org"  />',
      tab: 'Curriculum',
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    $routeProvider.when('/pairing', {
      template: '<react-app user="user" org="org"  />',
      hideNavbar: true,
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    $routeProvider.when('/help', {
      template: '<react-app user="user" org="org"  />',
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    $routeProvider.when('/curriculum/:bsBrand/:ageCategory/session/:postfix*', {
      template: '<react-app user="user" org="org"  />',
      tab: 'Curriculum',
      hideNavbar: true,
      reloadOnUrl: false,
      reloadOnSearch: false,
    });
    $routeProvider.when('/pairing/:postfix*', {
      template: '<react-app user="user" org="org"  />',
      hideNavbar: true,
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    $routeProvider.when('/assign-training/:trainingId*', {
      template: '<react-app user="user" org="org" />',
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    $routeProvider.when('/org/create', {
      template: '<react-app user="user" org="org"  />',
      hideNavbar: true,
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    $routeProvider.when('/org/switch', {
      template: '<react-app user="user" org="org"  />',
      hideNavbar: true,
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    $routeProvider.when('/org/:postfix*', {
      template: '<react-app user="user" org="org"  />',
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    $routeProvider.when('/manage-catalog', {
      template: '<react-app user="user" org="org"  />',
      tab: 'Organization',
      reloadOnUrl: false,
      reloadOnSearch: false,
    });
    $routeProvider.when('/manage-catalog/:postfix*', {
      template: '<react-app user="user" org="org"  />',
      tab: 'Organization',
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    $routeProvider.when('/manage-curriculum/:curriculumId/session/:sessionId/timeline/:timelineId/preview', {
      template: '<react-app user="user" org="org"  />',
      tab: 'Organization',
      hideNavbar: true,
      reloadOnUrl: false,
      reloadOnSearch: false,
    });
    $routeProvider.when('/manage-curriculum', {
      template: '<react-app user="user" org="org"  />',
      tab: 'Organization',
      reloadOnUrl: false,
      reloadOnSearch: false,
    });
    $routeProvider.when('/manage-curriculum/:postfix*', {
      template: '<react-app user="user" org="org"  />',
      tab: 'Organization',
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    $routeProvider.when('/org/settings', {
      template: '<react-app user="user" org="org"  />',
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    $routeProvider.when('/person/:id', {
      template: '<react-app user="user" org="org" />',
      tab: 'People',
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    $routeProvider.when('/email/:postfix*', {
      template: '<react-app user="user" org="org"  />',
      tab: 'People',
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    $routeProvider.when('/build/assessments/:postfix*', {
      template: '<react-app user="user" org="org"  />',
      tab: 'Organization',
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    $routeProvider.when('/curriculum/onboarding/:postfix*', {
      template: '<react-app user="user" org="org"  />',
      tab: 'Curriculum',
      hideNavbar: true,
      reloadOnUrl: false,
      reloadOnSearch: false,
      publiclyAccessible: true,
    });

    $routeProvider.when('/build/assessments/:postfix*', {
      template: '<react-app user="user" org="org" />',
      tab: 'Organization',
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    $routeProvider.when('/subscribe-bible-study/:postfix*', {
      template: '<react-app user="user" org="org" />',
      tab: 'Curriculum',
      reloadOnUrl: false,
      reloadOnSearch: false,
      publiclyAccessible: true,
    });

    $routeProvider.when('/assessment/:postfix*', {
      template: '<react-app user="user" org="org" />',
      tab: 'Organization',
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    $routeProvider.when('/admin/:postfix*', {
      template: '<react-app user="user" org="org" />',
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    $routeProvider.when('/manage-assignments', {
      template: '<react-app user="user" org="org" />',
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    $routeProvider.when('/manage-assignments/:postfix*', {
      template: '<react-app user="user" org="org" />',
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    $routeProvider.when('/purchase', {
      template: '<react-app user="user" org="org" />',
      hideNavbar: true,
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    $routeProvider.when('/401', {
      template: '<react-app user="user" org="org" />',
      hideNavbar: true,
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    $routeProvider.when('/browser-support', {
      template: '<react-app user="user" org="org" />',
      hideNavbar: true,
      reloadOnUrl: false,
      reloadOnSearch: false,
      publiclyAccessible: true,
    });

    $routeProvider.when('/manage-training/:postfix*', {
      template: '<react-app user="user" org="org"  />',
      tab: 'Organization',
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    $routeProvider.when('/pending-invites', {
      template: '<react-app user="user" org="org"  />',
      reloadOnUrl: false,
      reloadOnSearch: false,
      hideNavbar: true,
    });

    // separating this route from /invite to hide the navbar on this page only
    $routeProvider.when('/invite/accept/:orgId/:forEmail', {
      template: '<react-app user="user" org="org"  />',
      reloadOnUrl: false,
      reloadOnSearch: false,
      hideNavbar: true,
    });

    $routeProvider.when('/invite/:postfix*', {
      template: '<react-app user="user" org="org"  />',
      tab: 'Organization',
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    $routeProvider.when('/503', {
      template: '<react-app user="user" org="org"  />',
      hideNavbar: true,
      reloadOnUrl: false,
      reloadOnSearch: false,
      publiclyAccessible: true,
    });

    $routeProvider.otherwise({
      template: '<page-not-found />',
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    // end react-router routes

    $routeProvider.when('/add-to/:type/:id/:maxAdd?', {
      templateUrl: 'partials/views/add-to.html',
      controller: 'AddToCtrl',
    });

    $routeProvider.when('/invite/:orgId/:inviteType/:inviteId', {
      template: '<react-app user="user" org="org"  />',
      tab: 'Invites',
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    $routeProvider.when('/org', {
      template: '<react-app user="user" org="org"  />',
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    //Admin Root Level Views
    $routeProvider.when('/add-people', {
      tab: 'People',
      templateUrl: 'partials/views/add-people.html',
      controller: 'AddPeopleCtrl',
    });

    $routeProvider.when('/add-people', {
      tab: 'People',
      templateUrl: 'partials/views/add-people.html',
      controller: 'AddPeopleCtrl',
    });

    $routeProvider.when('/invites', {
      template: '<react-app user="user" org="org"  />',
      tab: 'Organization',
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    $routeProvider.when('/email-person/:orgId/:personId', {
      tab: 'People',
      templateUrl: 'partials/views/email-person.html',
      controller: 'EmailPersonCtrl',
    });

    $routeProvider.when('/add-step/:stepId', {
      templateUrl: 'partials/views/add-step.html',
      controller: 'AddStepCtrl',
    });

    $routeProvider.when('/course-settings', {
      templateUrl: 'partials/views/course-settings.html',
    });

    $routeProvider.when('/pathway-settings', {
      templateUrl: 'partials/views/pathway-settings.html',
    });

    // query params:
    // workflowId (optional): this view in the context of a workflow

    // query params:
    // taskType, order, isTasklistOwner (required)
    // workflowId (optional): this view in the context of a workflow
    addBuildRoutes('/courses/:tasklistId/content-select', {
      templateUrl: 'partials/views/content-select.html',
      controller: 'ContentSelectCtrl',
      header: { text: 'Course Builder' },
    });

    // query params:
    // taskType, order (optional): with no taskId, create a new task
    // workflowId (optional): this view in the context of a workflow
    addBuildRoutes('/courses/:tasklistId/tasks/:taskId?', {
      templateUrl: 'partials/views/task-edits.html',
      controller: 'TaskCreateEditCtrl',
      section: props.sections.build,
      header: { text: 'Course Builder' },
    });

    addBuildRoutes('/content/:contentId/:type?', {
      templateUrl: 'partials/views/content-builder.html',
      controller: 'ContentBuilderCtrl',
    });

    $routeProvider.when('/materials/:_type/:curriculumId/:issueId/:materialId/:order/:creating', {
      templateUrl: 'partials/views/material-edits.html',
      controller: 'MaterialCreateEditCtrl',
      section: props.sections.curriculum,
    });

    $routeProvider.when(
      '/materials/:_type/:curriculumId/:issueId/:sessionId/:materialId/:order/:creating/:packageType',
      {
        templateUrl: 'partials/views/material-edits.html',
        controller: 'MaterialCreateEditCtrl',
        section: props.sections.curriculum,
      }
    );

    $routeProvider.when('/materials/:_type/:curriculumId/:volumeId/:packageType/:materialId?', {
      templateUrl: 'partials/views/material-edits.html',
      controller: 'MaterialCreateEditCtrl',
      section: props.sections.curriculum,
    });

    $routeProvider.when('/curriculum-builder/:curriculumId/new', {
      templateUrl: 'partials/views/issue-new.html',
      controller: 'CurriculumBuilderCtrl',
      section: props.sections.curriculum,
    });

    $routeProvider.when('/issue-settings/:curriculumId/:issueId', {
      templateUrl: 'partials/views/issue-settings.html',
      controller: 'IssueSettingsCtrl',
      section: props.sections.curriculum,
    });

    $routeProvider.when('/curriculum/:curriculumId/issue/:issueId/support-materials-builder', {
      templateUrl: 'partials/views/session-builder.html',
      controller: 'SessionBuilderCtrl',
      section: props.sections.curriculum,
    });

    $routeProvider.when('/curriculum/:curriculumId/issue/:issueId/session-builder/:sessionId', {
      templateUrl: 'partials/views/session-builder.html',
      controller: 'SessionBuilderCtrl',
      section: props.sections.curriculum,
    });

    $routeProvider.when('/curriculum/:curriculumId/issue/:issueId/session-builder/:sessionId/settings', {
      templateUrl: 'partials/views/session-settings.html',
      controller: 'SessionSettingsCtrl',
      section: props.sections.curriculum,
    });

    //Dashboard Views
    $routeProvider.when('/licenses/:licenseId/:isPristine?', {
      templateUrl: 'partials/views/license-edit.html',
      controller: 'licenseEditCtrl',
      section: props.sections.org,
    });

    $routeProvider.when('/license-settings/:licenseId', {
      templateUrl: 'partials/views/license-settings.html',
      controller: 'licenseEditCtrl',
      section: props.sections.build,
    });

    $routeProvider.when(
      '/curriculum/:curriculumId/issue/:issueId/series/:seriesId/manage-material-viewer/:materialId',
      {
        tab: 'Organization',
        templateUrl: 'partials/views/material-viewer.html',
        controller: 'SupportMaterialViewerCtrl',
        section: props.sections.org,
      }
    );

    $routeProvider.when(
      '/curriculum/:curriculumId/issue/:issueId/series/:seriesId/session/:sessionId/manage-material-viewer/:materialId',
      {
        templateUrl: 'partials/views/material-viewer.html',
        controller: 'IssueMaterialViewerCtrl',
        section: props.sections.org,
      }
    );

    $routeProvider.when(
      '/curriculum/:curriculumId/issue/:issueId/series/:seriesId/session/:sessionId/build-material-viewer/:materialId',
      {
        templateUrl: 'partials/views/material-viewer.html',
        controller: 'IssueMaterialViewerCtrl',
        section: props.sections.org,
      }
    );

    $routeProvider.when('/curriculum/:curriculumId/issue/:issueId/manage-session-viewer/:sessionId', {
      templateUrl: 'partials/views/session-viewer.html',
      controller: 'SessionViewerCtrl',
      section: props.sections.org,
    });

    $routeProvider.when('/curriculum/:curriculumId/issue/:issueId/manage-support-materials-viewer', {
      templateUrl: 'partials/views/session-viewer.html',
      controller: 'SupportMaterialsViewerCtrl',
      section: props.sections.org,
    });

    $routeProvider.when('/training-progress', {
      template: '<react-app user="user" org="org" />',
      tab: 'Organization',
      reloadOnUrl: false,
      reloadOnSearch: false,
    });

    //Account-Participant Views

    // query params:
    // alt (optional): alternate preview mode flag
    $routeProvider.when('/training/:id', {
      templateUrl: 'partials/views/training.html',
      controller: 'TrainingCtrl',
      publiclyAccessible: true,
      section: props.sections.catalog,
    });

    $routeProvider.when('/training/:id/:isSelectTraining/:trainingType?', {
      templateUrl: 'partials/views/training.html',
      controller: 'TrainingCtrl',
    });

    $routeProvider.when('/catalog', {
      templateUrl: 'partials/views/training-catalog.html',
      publiclyAccessible: true,
      section: props.sections.catalog,
    });

    $routeProvider.when('/catalog/training/:id', {
      templateUrl: 'partials/views/catalog-training.html',
      controller: 'TrainingCtrl',
      publiclyAccessible: true,
      section: props.sections.catalog,
    });

    $routeProvider.when('/catalog/compilation/:id', {
      templateUrl: 'partials/views/training-compilation.html',
      publiclyAccessible: true,
      section: props.sections.catalog,
    });

    $routeProvider.when('/catalog/compilation/:id/:id', {
      templateUrl: 'partials/views/training-compilation-training.html',
      controller: 'TrainingCtrl',
      publiclyAccessible: true,
      section: props.sections.catalog,
    });

    $routeProvider.when('/curriculum/:curriculumId/issue/:issueId/session-viewer/:sessionId', {
      tab: 'Account',
      templateUrl: 'partials/views/session-viewer.html',
      controller: 'SessionViewerCtrl',
    });

    $routeProvider.when('/curriculum/:curriculumId/issue/:issueId/support-materials-viewer', {
      tab: 'Account',
      templateUrl: 'partials/views/session-viewer.html',
      controller: 'SupportMaterialsViewerCtrl',
    });

    $routeProvider.when('/curriculum/:curriculumId/issue/:issueId/series/:seriesId/material-viewer/:materialId', {
      tab: 'Account',
      templateUrl: 'partials/views/material-viewer.html',
      controller: 'SupportMaterialViewerCtrl',
    });

    $routeProvider.when(
      '/curriculum/:curriculumId/issue/:issueId/series/:seriesId/session/:sessionId/material-viewer/:materialId',
      {
        tab: 'Account',
        templateUrl: 'partials/views/material-viewer.html',
        controller: 'IssueMaterialViewerCtrl',
      }
    );

    // query params:
    // alt (optional): alternate preview mode flag
    $routeProvider.when('/training-task/:id/:taskIndex', {
      tab: 'Catalog',
      templateUrl: 'partials/views/training-task.html',
      controller: 'TrainingTaskCtrl',
      publiclyAccessible: true,
    });

    $routeProvider.when('/training-task/:id/:taskIndex/:isSelectTraining', {
      templateUrl: 'partials/views/training-task.html',
      controller: 'TrainingTaskCtrl',
    });

    $routeProvider.when('/dashboard', {
      tab: 'Account',
      templateUrl: config.isCurriculumEnabled
        ? 'partials/views/dashboard.html'
        : 'partials/views/dashboard-training.html',
      controller: config.isCurriculumEnabled ? 'DashboardController' : 'DashboardTrainingController',
    });

    $routeProvider.when('/dashboard-training', {
      tab: 'Account',
      templateUrl: 'partials/views/dashboard-training.html',
      controller: 'DashboardTrainingController',
    });

    $routeProvider.when('/dashboard-history', {
      tab: 'Account',
      templateUrl: 'partials/views/dashboard-history.html',
      controller: 'DashboardTrainingController',
    });

    $routeProvider.when('/prepare-enrich', {
      tab: 'Organization',
      templateUrl: 'partials/views/prepare-enrich.html',
      controller: 'PrepareEnrichCtrl',
    });

    $routeProvider.when('/prepare-enrich-taker/:id/:userId/:orgId/:taskInstanceId/:email/:loggedIn', {
      tab: 'Account',
      templateUrl: 'partials/views/prepare-enrich-taker.html',
      controller: 'AssessTakerPublicCtrl',
    });

    $routeProvider.when('/redeem-invite/:prefix?/:suffix?', {
      templateUrl: 'partials/views/redeem-invite.html',
      controller: 'redeemInviteCtrl',
      hideNavbar: true,
    });

    $routeProvider.when('/easy-link/:prefix/:groupCode?', {
      templateUrl: 'partials/views/redeem-easy-link.html',
      controller: 'redeemEasyLinkCtrl',
      hideNavbar: true,
    });

    //Training Views
    $routeProvider.when(
      '/assessment-viewer-publicTraining/:id/:userId/:orgId/:taskListInstanceId?/:taskInstanceId/:email/:loggedIn/:fromTaker?/:workflowId?',
      {
        tab: 'Training',
        templateUrl: 'partials/views/assessment-viewer-publicTraining.html',
        controller: 'AssessPublicCtrl',
        publiclyAccessible: true,
      }
    );

    $routeProvider.when('/assessment-viewer/:taskListInstanceId/:taskInstanceId/:fromTaker?', {
      tab: 'Training',
      templateUrl: 'partials/views/assessment-viewer-publicTraining.html',
      controller: 'AssessViewerCtrl',
      publiclyAccessible: true,
    });

    $routeProvider.when('/assessment-taker/:taskListInstanceId/:taskInstanceId/:id', {
      tab: 'Training',
      templateUrl: 'partials/views/assessment-taker-publicTraining.html',
      controller: 'AssessTakerCtrl',
      publiclyAccessible: true,
    });

    $routeProvider.when(
      '/assessment-taker-publicTraining/:id/:userId/:orgId/:taskListInstanceId/:taskInstanceId/:email/:loggedIn/:workflowId?',
      {
        tab: 'Training',
        templateUrl: 'partials/views/assessment-taker-publicTraining.html',
        controller: 'AssessTakerPublicCtrl',
        publiclyAccessible: true,
      }
    );

    $routeProvider.when('/feedback-viewer/:id/:taskListInstanceId/:isPersonal?', {
      tab: 'Account',
      templateUrl: 'partials/views/feedback-viewer.html',
      controller: 'FeedbackCtrl',
    });

    $routeProvider.when('/training-viewer/:type/:id', {
      tab: 'Training',
      templateUrl: 'partials/views/training-viewer.html',
      controller: 'TrainingViewerCtrl',
    });

    $routeProvider.when('/training-steps/:trainingId', {
      templateUrl: 'partials/views/training-steps.html',
      controller: 'StepsViewerCtrl',
      hideNavbar: true,
    });

    $routeProvider.when('/feedback-assessment-taker/:courseId/:stepId/:email', {
      templateUrl: 'partials/views/assessment-feedback-step.html',
      controller: 'AssessmentFeedbackStepViewerCtrl',
      hideNavbar: true,
      publiclyAccessible: true,
    });

    $routeProvider.when('/task-viewer/:courseId/:taskId?/:workflowId?', {
      tab: 'Training',
      templateUrl: 'partials/views/task-viewer.html',
      controller: 'TaskViewerCtrl',
    });

    $routeProvider.when('/locked-tasklist-task-viewer/:id/:taskIndex', {
      tab: 'Training',
      templateUrl: 'partials/views/locked-tasklist-task-viewer.html',
      controller: 'LockedTaskListTaskViewerCtrl',
    });

    $routeProvider.when('/select-org-and-assign/:trainingId', {
      templateUrl: 'partials/views/select-org-and-assign.html',
      controller: 'SelectOrgAndAssignCtrl',
      hideNavbar: true,
    });

    $routeProvider.when('/training-pathway/:pathwayId', {
      templateUrl: 'partials/views/training-pathway.html',
      controller: 'TrainingPathwayCtrl',
      hideNavbar: true,
      publiclyAccessible: true,
    });

    $routeProvider.when('/create/:fromLogin?', {
      redirectTo: '/org/create',
    });

    $routeProvider.when('/impersonate', {
      redirectTo: '/admin/impersonate',
    });

    $routeProvider.when('/accept-invite/:orgId/:forEmail', {
      redirectTo: params => `/invite/accept/${params.orgId}/${params.forEmail}`,
    });

    //Default Views
    $routeProvider.when('/login', {
      templateUrl: 'partials/views/login.html',
      controller: 'loginCtrl',
      hideNavbar: true,
      publiclyAccessible: true,
    });

    $routeProvider.when('/logout', {
      templateUrl: 'partials/views/logout.html',
      controller: 'logoutCtrl',
      hideNavbar: true,
      publiclyAccessible: true,
    });

    $routeProvider.when('/oauth2/:path', {
      templateUrl: 'partials/views/logging-in.html',
      controller: 'loginCtrl',
      hideNavbar: true,
      publiclyAccessible: true,
    });

    $routeProvider.when('/purchaseCheck', {
      redirectTo: '/pairing/checkout',
    });

    $routeProvider.when('/', {
      tab: 'Login',
      templateUrl: 'partials/views/login.html',
      controller: 'loginCtrl',
      hideNavbar: true,
      publiclyAccessible: true,
    });

    //Style Specs View
    $routeProvider.when('/style-specs', {
      templateUrl: 'partials/views/style-specs.html',
      controller: 'styleSpecsCtrl',
      hideNavbar: true,
    });

    blockUIConfigProvider.delay(0);
    blockUIConfigProvider.autoBlock(false);

    //Onboarding View
    $routeProvider.when('/onboarding/:onboardingName/:action?', {
      templateUrl: 'partials/views/onboarding.html',
      controller: 'onboardingCtrl',
      hideNavbar: true,
      publiclyAccessible: true,
    });

    $routeProvider.when('/add-to-your-training/:trainingId', {
      templateUrl: 'partials/views/self-assign-link.html',
      controller: 'selfAssignLinkCtrl',
      hideNavbar: true,
      publiclyAccessible: true,
    });

    // utility for adding 2 similar routes for build and author pages (and optional scorecards page)
    function addBuildRoutes(route, config, addScorecardsRoute) {
      $routeProvider.when('/build' + route, {
        templateUrl: config.templateUrl,
        controller: config.controller,
        section: config.section || props.sections.build, // optional
        header: config.header, // optional
      });

      $routeProvider.when('/author' + route, {
        templateUrl: config.templateUrl,
        controller: config.controller,
        section: props.sections.author,
      });
    }
  },
]);
